/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/

.slideshow_caption p.h1, .Home h2 { font-size: 2.5em; margin-bottom: 0.2em; }
.slideshow_caption p.hideonMobile {font-size: 1.2em;}
.slideshow_caption .slideDescription { margin: 0.7em 0 1em 0; font-size: 1.2em; }
body.Home .columnlayout h2  { font-size: 1.6em; }
#footer { font-size: 1em; }
#bottomblocks .button { padding: 0.6em 2em; } 
 .accessories{font-size: 1.4em;}
.downCTA{font-weight: 600; font-family: $headings; text-transform: uppercase;}

.hideonMobile {display: block;}
.hideonDesktop {display: none;}

.mobileseasonalbanner {display: none;}
.desktopseasonalbanner{display: inline;}

/*************************
Colours
*************************/
.green, .topMenu .nav > li > a {
    color: $black;
    
}

.green, .accessories .holdCart a {color: $color-primary;}

.darkgreen, .topMenu .nav > li > a:hover { color: $color-tertiary; }

.white, .downCTA a, .downCTA a:hover, .downCTA a:focus{color: $white;}

.black, .phone, .phone a, .accessories a{color: $black;}

/*********************
LAYOUT & GRID STYLES
*********************/
.row {
	padding: 2em 0;
}
/* Reduce padding on some rows*/
#breadcrumbs .row {padding: 1em 0;}


/*********************
SPRITE
*********************/
		
	#logo {
		width: 350px;
		height: 120px;
		background-size: 350px 120px;
	}

/*********************
HEADER STYLES
*********************/

body.Home .header .menu {
    background: transparent;
    
}

body.Home.home2 .header .menu {
    background: rgba(255,255,255,0.9);
    
}

.header {
	.row {
		position: relative;
	}
	#logo {
		
	}

    .accessories{
        position: absolute;
        right: 0;
        top: 1em;
        width: 30%;
        transform: none;
        
    
    }

   
	.phone {
 
        display: inline-block;
        vertical-align: top;
     
		text-align: left;
		> div {
			display: inline-block;
			padding:  0 0.8em;
			&:first-child {
				padding-left: 0;
				border-left: 0;
			}
			
		}
	}
	.menu {	
		padding: 0.5em 0;
        
		.last { 
			float:right;
			text-align: left;
			margin-bottom: 0em;
			padding: 0 0;
			width: auto;
			nav {
				float:none;
				line-height: 1.5;
				
				
			}
			
			
			
		}
		
	}
	
	
	
		#secondmenu {
			display: block;
			}
}

/**************************
HOME STYLES
***************************/
body.Home{
     .header {
            min-height: 700px;
    #topblocks {
      width: 70%;
        @include center();
        background: transparent;
    #block_34 .sixcol:hover{
        cursor: pointer;
    }

    .sixcol{
           /*h2{padding: 0;}*/
    }
  }
}
.header{

       height: 100vh;
    

 #imageslider {
        height:100vh; 
        overflow:hidden;
        position: absolute;
        top: 0;
        width: 100%;

        video{
            z-index: -2;
            min-height: 100%;
            max-width: none;
            min-width: 100%;
            max-height: none;
        }

     }

    &.home2 #imageslider{
        height:auto; 
        overflow:hidden;
        position: relative;
        margin-top: -207px;
        width: 100%;
        border-bottom: 4px solid $color-primary;
        }

 }



#down {
    @include center(true,false);
    bottom: 5%;
    z-index: 1;
    text-align: center;
   
        &:hover{
            cursor: pointer;
        }

        .downCTA{
            background: $color-primary;
            box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.4);
            padding: 1em 2.5em;
            margin: 1em;
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 2px;
          
            @include transition(all 0.4s ease-in-out);
            animation-delay: 3s;
         

        &:hover{
             background: $color-tertiary;
             @include transition(all 0.4s ease-in-out);
          }

        }

    
        }
}

body.Home.home2 #down{display: none;}


body.Home.home2 #topblocks{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    transform: none;
    padding: 0;
   /*background: lighten($color-primary, 45%);
    border: none;
    border-bottom: 4px solid $color-primary;
    box-shadow: 0px -1px 10px 2px rgba(0,0,0,0.3) inset, 0px 1px 10px 2px rgba(0,0,0,0.3) inset;*/
    .row{
     
    }

}


/*********************
NAVIGATION STYLES
*********************/

.menuWrap {
	
}
#mainMenu {
	padding: 1em 0 0.5em 0;
}
.topMenu {
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    clear: none;


    a.toggleMenu {
        display: none;
    }

    .nav {
        position: relative;
        width: auto;
        margin-top: 0;
       


        > li {
            display: inline-block;
            padding: 0 0.6em;

            > a {

                &:after {
				content: '';
				position: absolute;
				display: block;
				width: 0;
				height: 2px;
				left: 0;
				bottom: 0;
				right: auto;
				width: 0;
				z-index: 999;
				background-color: $color-primary;
				@include transition(all 0.3s ease-in-out 0s);
			}

			    &:hover:after, &.activerootmenulink:after  {
				    right: 0;
				    left: auto;
				    content: '';
				    position: absolute;
				    display: block;
				    width: 100%;
					
				    height: 2px;
				    @include transition(all 0.3s ease-in-out 0s);
			    }

                &:hover, &:focus, &:active {
                    background: transparent;
                    outline: none;
                }

                /*&.parent:after {
                    content: "\f107";
                    font-family: 'Font Awesome 5 Pro';
                    display: block;
                    float: right;
                    font-size: large;
                    padding-left: 0.3em;
                    line-height: 1.5;
                    font-weight: 600;
                }*/
            }

             &.hover > a.parent:after {
                    content: "\f106";
                    font-family: 'Font Awesome 5 Pro';
                    display: block;
                    float: right;
                    font-size: large;
                    padding-left: 0.3em;
                    line-height: 1.5;
                    font-weight: 600;
                }
            /*Prominent contact link*/
            &:first-child {
                display: none;
            }

            &:last-child {
            
            }
            /*turn some of the main nav items OFF for public*/
            /*&:first-child > a{
				display:none;
			}
			
			&:nth-child(6) {
				display:none;
			}
			&:nth-child(7) {
				display:none;
			}
			*/
        }

        li {
            position: relative;

            a {
                padding: 0.5em 0em;
                background: none;
                border-bottom: none;


                &.parent:before {
                    display: none;
                }
            }
            /* highlight current page */
            a.activerootmenulink {
                color: $color-tertiary;
            }

            &:first-child {
            }

            &:last-child {
            }
            /*
			plan your menus and drop-downs wisely.
			*/
            ul,
            ul.sub-menu,
            ul.children {
                position: absolute;
                z-index: 9999;
                left: -9999px;
                border-top: 2px solid $color-primary;

                li {

                    a {
                        padding: 0.5em 0.5em;
                        display: block;
                        width: 200px;
                        border-top: none;
                        border-radius: 0;
                        margin-right: 0;


                        &:hover,
                        &:focus {
                            border-top: none;
                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {

                        a {
                        }
                    }
                    /*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
                    ul {
                        border-top: none;
                    }
                }
            }
            /* showing sub-menus */
            &:hover ul {
            }
        }
        /* end .menu ul li */
    }
    /* end .nav */
} /* end .topMenu */

.nav > li.hover > ul { left: 0;}
.nav li li ul { left: -9999px; z-index: 99; position:absolute; }
.nav li li.hover ul { left: 100%; top: 0; z-index: 99; position:absolute; }

/* active state on home - not sure where else to put this for now*/


/*************************
TOPBLOCKS
************************/  

/*********************
BLOCK LAYOUT
**********************/	


.columnlayout, ul#products, #categorydetails ul, #relatedProducts ul {
	
	> div, li {
		width: 24%;
		margin-right: 0.5em;
					
			&:nth-child(2n+2) {
				margin-right:0.5em;
			}
			&:nth-child(3n+3) {
				margin-right:0;
			}
			
		}
}


/*************************
PRODUCTS
**************************/
.product .sevencol.last .figure{
            display: inline-block;
            display: inline-flex;
	        flex-direction: column;
	        flex-wrap: wrap;
            width: 18%;
            margin-left: 2%;
            margin-bottom: 1em;
            text-align: center;
            &:nth-child(even){
                margin-right: 0;
            }
        }


#stickyselectionholder{
    
       

     .stickyItem:last-of-type{
      
       /*&:after{
           content: '';
           text-align: center;
           color: $color-quaternary; 
           line-height: 105px;
           font-size: 4em;
           position: absolute;
           right: -120px;
           top: 2.5%;
           border: 1px dashed $color-quaternary;
           width: 100px;
           height: 95%;
           display: block;
           opacity: 0.4;
       }*/
       /*&:before{
           content: 'add to your collection';
           @include center(false, true)
           text-align: center;
           right: -142.5px;
           width: 150px;
           color: $color-quaternary;   
           opacity: 0.8;
       }*/

     }
    }  

/*************************
SLIDESHOW
*************************/  	

	
/*Full width slider */
ul.responsive3 {
	.slick-list {
		
		.slick-track {
			
			.slick-slide {
				
					.slideshow_caption { 
						padding: 2em;
                        width:40%;
                        min-width:300px;
                        top: 60%;
                       
						
						.slideDescription {
							display: block;
						}
					}
				
				img {
					display: block;
					height: auto;
					float: left;
					width: initial;
					max-width: initial;
				}
			}
			
		}
	}
}

.slick-prev, .slick-next { top: 40%; }

  table.ViewCart  a#lnlContinueShopping2, table.ViewCart .button.secondary{
            display: block;
            width: 49%;
            max-width: 300px;
            text-align: center;
            margin: 0.25em 0;

  }

/*************************
THIS IS FOR THE PARALLAX SCROLL
*************************/  
 #trusticons {
     h4 {font-size:16px;}
            .parallax-window {
                position: relative;
                padding: 5em 0;
                height: 500px;
                text-align: center;
                ;
                margin-top: 0;
            }

 }
            /*************************
STICKY BACK TO TOP
*************************/
            .cd-top {
                height: 60px;
                width: 60px;
                right: 30px;
                bottom: 30px;
            }

