/******************************************************************
Site Name: 
Author: 

Stylesheet: Desktop Stylsheet

This is the desktop size. It's larger than an iPad so it will only
be seen on the Desktop. 

******************************************************************/


/* STICKY NAV - This is where the header is set to be sticky. 
We can also change colour and size here - effects all take place onScroll (waypoints triggers this) */



#content {height:auto;}

.slick-prev, .slick-next { top: 50%; }
.slick-next { right: 50px; }
.slick-prev { left: 50px; }
.slick-slider .slick-track, .slick-slider .slick-list { -webkit-perspective: 1000px; }