/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet

Here's where you can start getting into the good stuff.
This size will work on iPads, other tablets, and desktops.
So you can start working with more styles, background images,
and other resources. You'll also notice the grid starts to
come into play. Have fun!

******************************************************************/
/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/
.slideshow_caption p.h1, .heading h1 { font-size: 2.5em; }
#footer { font-size: 0.9em; }
blockquote .h2, body.Home #topblocks h2 { font-size: 1.9em; margin-bottom: 0em;}


/*********************
GENERAL STYLES
*********************/

body {}



/*********************
LAYOUT & GRID STYLES
*********************/

.row {
	max-width: 1240px;
}
header .row {
	max-width: 1600px;
}




/*********************
HEADER STYLES
*********************/
body.Home .header {
 #topblocks {
	/*@include center(); */
    padding: 0;
    z-index: 1;
    text-align:center;
    width: 100%;
    margin: 0 auto;
    
    
    padding: 2em;
    

    h1{
        margin: 0;
    }

    .sixcol{
      
        padding: 0;
        
        /*box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.3);*/
        margin-top: 1em;
        h2{
               a,i{display: inline; word-break:keep-all;}
               position: relative;
            


        }
     


        &.first, &.last{
             background: rgba(255,255,255,0.8);
             @include transition(all 0.5s ease-in-out);
        }

        &:hover{
            background: $white;
            @include transition(all 0.5s ease-in-out);
            box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.3);
        }
    
    img{display: none;}    
    }
}
}

/*Set height of parallax on home to be bigger than inner pages */

.header, .header .parallax-window {
}

.header {
	.menu {
		margin: 0 auto;
		width: 100%;
	}
	
	.phone {
		line-height: 3;
		position: relative;
		text-align: right;
		span {
			display: inline-block;
			margin: 0 0.5em;
		}
		.email {
			display: inline-block;
		}
		.button {
			float: none;
		}
	}
	
	
	#logo {
		
	}
	
}

/***********************
HERO - INNER PAGES
************************/
#hero {
	.topBlock {

		h1,.h1 {
			padding: .5em 1em;
			width: auto;
			bottom: inherit;
			@include center(); 
		}
	}
}




/*********************
NAVIGATION STYLES
*********************/


.menuWrap {
	width: 95%;
    margin: 0 auto;
}


/*********************
CONTENT STYLES
*********************/
/* content area all inner pages */
#content {
	.row {
	}
}
	

	
	

blockquote {
	&:before {
		/*margin-bottom:5em;*/
		float: left;
		font-size: 2em;
	}
}





/*********************
HOME STYLES
*********************/

body.Home #content #leftcol {
                    margin-top: 2.5em;
                    }

/*********************
LANDING PAGE STYLES
**********************/

body.Landing {
	#content {
		#leftcol {
			> div {
				padding: 4em 0;
			}
		}
	}
}


/*********************
BLOCK LAYOUT
**********************/	

.columnlayout {
	> div {
		display:-moz-inline-stack; 
		display:inline-block;
		vertical-align: top;
			div {
				width: auto;
			
			}
	}
}

/*********************************
TOP BLOCKS
*********************************/



/*********************************
BOTTOM BLOCKS
*********************************/
#bottomblocks {
	.parallax-window {
		padding: 3em 0;
		.borderWrap {
			width: 60%;
		}
	}
}

/*********************
RIGHT BLOCKS
*********************/

#rightcol {
	/*margin-top: 0;*/
}

/*********************
NEWS LISTING
*********************/
	

/*********************
GALLERY
*********************/

#gallery {
	
		li {
		
			width: 22.5%;
			margin-right: 2%;
		
		}
	
}

/*********************
VIDEOS
*********************/


/*************************
THIS IS FOR THE PARALLAX SCROLL
*************************/
#trusticons {

    .parallax-window {
        position: relative;
        padding: 5em 0;
        height: 500px;
        text-align: center;
        ;
        margin-top: 0;
    }
}

/*********************
FOOTER STYLES
*********************/
#confidence {
		background: black;
}
#address {
	form { 
		margin: 0 0;
	}
}

 .confidenceTriggers{
    
        min-height: 300px;
}

#footer {
	> div {
		/*text-align: left;*/
		#copyright {
			.first {
				a {
					display: inline;
				}
			}
		}
		#privacy {
			text-align: right;
		}
		#facebook {
			text-align: center;
		}
		
	}
}

/*
you'll probably need to do quite a bit
of overriding here if you styled them for
mobile. Make sure to double check these!
*/


/*SLIDER*/
/* Bug fix for flashing on Slick slideshow */

/*Full width slider */
ul.responsive3 {
	.slick-list {
		
		.slick-track {
			
			.slick-slide {
				img {
					height: 50vh;
				}
			}
			
		}
	}
}



/******************************************************************
TABLE STYLES
******************************************************************/

/* RESPONSIVE TABLES */
	table.tableFixed { 
		table-layout:fixed;
	}
	table#tableCart, table.tableResp, table#tableTotals{ 
		display: table;
		
		thead {
			tr {
				position: relative;
				top:0;
				left:0;
                display: table-row;
				
				td, th {
					padding: 0.25em;
					/*background-color: darken($white, 8%);*/
                    width: auto;
                    display: table-cell;
				}
				
			}
		}
		
		tbody {
			display: table-row-group; 
			
			tr {
				display: table-row; 
				border-bottom: none;

                 &.ViewCart_Separator{
                     border: none;
                     background: $color-quaternary;
                     color: white;
                 }

                &.isAttribute, &.productCartOptions{
                    
                    border: none;
                    font-size: 90%;
                    td{
                        border: none;
                        margin: 0;

                        .imageholder{
                            padding-left: 30%;
                        }

                    }

                }
				
				td {
					display: table-cell; 
					text-align: left;
					width: auto;
					
					/* Add this class to td's that need to avoid text-wrap */
					&.Stretch {
						word-wrap: break-word;
					}
					&.noStretch {
						white-space:nowrap;
					}
					
					/* Hide mobile headings */				
					&:before {
						display: none;
					}
					
					&.text-right {
						text-align: right;
					}
					&.text-center {
						text-align: center;
					}
				}
				
			}
		}
		
	}
	
	table#tableTotals { 
		tbody {
			tr {
				td { 
					border:none;
					&:first-child { border-right:4px solid darken($white, 8%);}
					&:last-child {width:10%;}
				}
			}
			tr:not(.calcPostage) {
				td { 
					&:first-child {float:none;}
				}
			}
		} 
	}

    table#tableCart tbody tr td:nth-child(2),  table#tableCart tbody tr td:last-child{display: table-cell; width: auto;} 
    table#tableCart tbody tr td:last-child .cartPrice{border-top: none; padding-top: 0;}
    table#tableCart tbody tr td:first-child img{margin: 0; display: inline;}
    table#tableCart thead {display: table-header-group; background: $color-secondary; color: $white;}
    table#tableCart .isCollectionFooter{
        display: table-row; 
        
        td:last-child{
            border-top: 1px solid $color-secondary;
            text-align: center;
        }

    }
/**********************************
Cart + Checkout
**********************************/


body.viewcart #checkoutBreadcrumbs {
	li {
		a {
			font-size: 100%;
			  text-indent: 0em !important;
		}
	}
	li.first {
		width: 40%;
	}
	li.second, li.third, li.fourth {
		width: 20%;
	}

}
body.checkout #checkoutBreadcrumbs {
	li {
		a {
			font-size: 100%;
	}	}
	li.first, li.fourth {
			a {
				text-indent: 0em;
		}
	}
}

body.checkout {
	#checkoutBreadcrumbs {
		li.first, li.fourth {
			a {
				text-indent: 0em;
			}
		}
	}
}
#checkoutBreadcrumbs {
	li {
		width: 33% !important;
	}
}




.ViewCart {
	td.ViewCart_Price_Left {
		#divPopup {
			color: $black;
			font-weight: normal;
			margin: 10px 0px 0px auto;
			padding: 10px 30px 10px 10px;
			position: absolute;
			right: 150px;
			text-align: left;
			width: 240px;
			height: 60px;
			text-transform: none;
			font-size:100%;
			#postcodeSearchInput {
				float: left;
				margin-right: 10px;
			}
		}
		.ViewCart_Freight_Good,
			.ViewCart_Freight_Good_Quote {
				background: URL('/wmsimages/freight_good.png') no-repeat;
		}
		.ViewCart_Freight_Bad, 
		div.ViewCart_Freight_Bad_Quote {
			background: URL('/wmsimages/freight_bad.png') no-repeat;
		}
	}
	.freight {
		display:table-cell; 
	}
}
.Proceed { 
	font-size: 1.5em; 
    a{width: 100%;}
}



body.checkout {
	#rightcol.last {
		float: none;
		width:100%;
		margin-left: 0;
	}
	#holdLeft.first {
		float: none;
		width:100%;
	}
}


